<template>
  <div id="parent"
    style="background-color:  #f6f6f6; height: 100%;position: static;width: 100%;-webkit-overflow-scrolling: touch;overflow-y: scroll;">
    <van-nav-bar v-if="navigationShow" left-arrow
      @click-left="$router.push({ path: '/ordersCenter', query: { session: token } })" fixed placeholder>
    </van-nav-bar>

    <!-- 订单详情 -->
    <div id="element" style="z-index: 999;position: relative;width: 100%;" ref="container"
      @touchmove="touchmove($event)" @touchstart="touchstart($event)" @touchend="touchend($event)">
      <!-- 等待支付/商品已送达/订单已完成/订单已取消 -->
      <div class="status">
        <!-- 等待支付 -->
        <span v-if='currentType==1'>等待支付，剩余</span>
        <van-count-down @finish="finish" v-if='currentType==1' :time="time" />
        <!-- 订单状态 -->
        <div id="formStatus" v-if="navigationShow" style="opacity: 1" @click="popup">
          <span>{{titleCurrent}}</span>
          <van-icon name="arrow" />
        </div>
      </div>

      <div v-if='currentType==1 || currentType==5' class="box pay-explain">
        <div style="flex-shrink: 0; margin-right: 5px">
          <img src="../../../assets/img/takeOut/bell.png" alt="" style="width: 16px; height: 16px; display: block" />
        </div>
        <span>支付后预计送达时间将更新，越早支付，越早送达。超过15分钟未支付，订单将自动取消。</span>
      </div>

      <div class="box" style="padding: 0">
        <div style="
          padding: 11px 0;
          margin: 0 11px;
          border-bottom: 1px solid #e5e5e5;
        ">
          <!-- 1 -->
          <div v-if='currentType==1 || currentType==5' style="font-size: 17px; font-weight: 500; color: #333333">
            <span>预计</span>
            <span style="color: #ff5200">{{currentData.expectDeliveTime}}</span>
            <span>到达</span>
          </div>
          <!-- 1 -->
          <div v-if='currentType==1 || currentType==5' style="
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                padding-top: 5px;
              ">
            由315快送配送
          </div>
          <!-- 2/3 -->
          <div v-if='currentType==2 || currentType==3 || currentType==6'
            style="font-size: 17px; font-weight: 500; color: #333333">
            <span>感谢您对315外卖的信任，期待再次光临</span>
          </div>
          <!-- 2/3 -->
          <div v-if='currentType==2 || currentType==3 || currentType==6' style="
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          padding-top: 5px;
        ">
            温馨提示：请厉行节约，拒绝浪费
          </div>
          <!-- 4 -->
          <div v-if='currentType==4 ' style="font-size: 17px; font-weight: 500; color: #333333">
            <span>{{buttonTopContent}}</span>
          </div>
        </div>

        <!-- 按钮 -->
        <div class="btns-box">
          <div @click="onSubmit" class="btn" v-if="currentType==1">
            <img src="../../../assets/img/takeOut/Money.png" class="btn-img" />
            <div class="btn-text">立即支付</div>
          </div>
          <div @click="submit(5)" class="btn" v-if="currentType==5">
            <img src="../../../assets/img/takeOut/Cancel.png" class="btn-img" />
            <div class="btn-text">取消订单</div>
          </div>
          <div @click="push(2)" class="btn" v-if="currentType==3 && currentData.cancelStatus !=1 && overtime">
            <img src="../../../assets/img/takeOut/Money.png"
              :style="currentData.cancelStatus==3?'opacity: .5':'opacity: 1'" class="btn-img" />
            <div :style="currentData.cancelStatus==3?'opacity: .5':'opacity: 1'" class="btn-text">申请退款</div>
          </div>
          <div class="btn" @click="push(1)" v-if="currentData.cancelStatus == 1 && overtime">
            <a id="tel" href="tel:400-8721-8321" style="display: flex;flex-direction: column;align-items: center;"></a>
            <img src="../../../assets/img/takeOut/Profile.png" class="btn-img"
              :style="currentData.complaintStatus==1?'opacity: .5':'opacity: 1'" />
            <div :style="currentData.complaintStatus==1?'opacity: .5':'opacity: 1'" class="btn-text">申请售后</div>
          </div>
          <div class="btn" @click="contact1" v-if="true">
            <img src="../../../assets/img/takeOut/Chat.png" class="btn-img" />
            <div class="btn-text">联系商家</div>
          </div>
          <div @click="submit(1)" class="btn" v-if="currentType==1">
            <img src="../../../assets/img/takeOut/Cancel.png" class="btn-img" />
            <div class="btn-text">取消订单</div>
          </div>
          <div class="btn" @click="recur" v-if="currentType!==1">
            <img src="../../../assets/img/takeOut/Plus.png" class="btn-img" />
            <div class="btn-text">再来一单</div>
          </div>
        </div>
      </div>

      <!-- 订单基础信息 -->
      <div v-if="currentType==1 || currentType==5" class="box">
        <div class="box-title">订单基础信息</div>
        <div class="box-content">
          <div class="content-inner">
            <div>配送地址</div>
            <div
              style="width: 200px;text-overflow: ellipsis;white-space:normal;overflow: hidden;text-align: right;line-height: 20px;">
              {{currentData.acceptAddress}}</div>
          </div>
          <div class="content-inner" style="display: flex;margin-top: -5px;">
            <div></div>
            <div>{{currentData.acceptPhone}}</div>
          </div>
          <div class="content-inner">
            <div>备注</div>
            <div>{{currentData.userRemark}}</div>
          </div>
        </div>
      </div>

      <!-- 退款 -->
      <div v-if="refund" class="box box-title"
        style="height: 2rem;display: flex;justify-content: space-between;align-items: center;">
        <div class="btn-text">退款</div>
        <div style="color: #A80000;">¥{{currentData.payFee}}</div>
      </div>

      <!-- 商品 -->
      <div class="box">
        <div class="box-title">{{currentData.orderName}}</div>
        <div class="goods">
          <div v-for="(item, index) in currentData.takeoutOrderGoods" :key="index" class="goods-item">
            <img style="width: 60px;height: 60px;border-radius: 10px; overflow: hidden; flex-shrink: 0"
              :src="item.goodsUrl" />
            <div style="flex: 1; margin-left: 10px">
              <div class="item-title">
                <div>{{item.goodsName}}</div>
                <div>￥{{item.goodsTotalPrice}}</div>
              </div>
              <div>
                <div style="display: flex;width: 200px;flex-wrap: wrap;">
                  <van-tag style="margin-right:8px" color="#eee" text-color="#999"
                    v-for="(j,k) in item.goodsStandardNames" :key="k">{{j}}
                  </van-tag>
                </div>
              </div>
              <div>x{{item.goodsNum}}</div>
            </div>
          </div>
        </div>
        <div class="box-content">
          <div class="content-inner" style="color: #333">
            <div>打包费</div>
            <div>￥{{currentData.pricePackage}}</div>
          </div>
          <div class="content-inner" style="color: #333">
            <div>配送费</div>
            <div>￥{{currentData.priceTransportUser}}</div>
          </div>
          <div class="content-inner" style="color: #333">
            <div>优惠券</div>
            <div>-￥{{currentData.priceCouponReduce?currentData.priceCouponReduce:0}}</div>
          </div>
          <div class="content-inner" style="color: #333">
            <div></div>
            <div>
              <span>已优惠￥{{priceCouponReduce}} 合计：</span>
              <span style="color: #a80000">
                <span></span>
                <span style="font-size: 18px">￥{{currentData.payFee}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- 配送信息 -->
      <div class="box">
        <div class="box-title">配送信息</div>
        <div class="box-content">
          <div class="content-inner">
            <div>配送地址</div>
            <div
              style="width: 200px;text-overflow: ellipsis;white-space:normal;overflow: hidden;text-align: right;line-height: 20px;">
              {{currentData.acceptAddress}}</div>
          </div>
          <div class="content-inner">
            <div></div>
            <div style="display: flex;margin-top: -5px;">
              <div>{{currentData.acceptName }}&nbsp; </div>
              <span> {{currentData.acceptPhone}} </span>
            </div>
          </div>
          <div class="content-inner">
            <div>配送服务</div>
            <div>Win生活快送配送</div>
          </div>
        </div>
      </div>

      <!-- 订单信息 -->
      <div class="box">
        <div class="box-title">订单信息</div>
        <div class="box-content">
          <div class="content-inner">
            <div>订单号码</div>
            <div>{{currentData.id}}</div>
          </div>
          <div class="content-inner">
            <div>下单时间</div>
            <div>{{currentData.createTime}}</div>
          </div>
          <div class="content-inner">
            <div>支付方式</div>
            <div>{{currentData.payType==3?'支付宝':'微信'}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 订单跟踪 -->
    <van-action-sheet v-model="actionShow" title="订单跟踪">
      <div class="content" style="padding: 0 1.8rem;">
        <!-- 订单已提交 -->
        <div v-for="(item,index) in tracking" :key="index"
          style="display: flex;justify-content: space-between;padding: .625rem 0;">
          <div style="display: flex;">
            <!-- left -->
            <div style="margin-right: 1rem;display: flex;flex-direction: column;align-items: center;width: 5px;">
              <div style="width: 2px;height: 1rem;background-color: #D8D8D8 ;margin-bottom: .3125rem;"></div>
              <div :class="tracking.length-1==index?'currentStyle':'defaultStyle'"></div>
            </div>
            <!-- 内容 -->
            <div style="color: #999999;">{{Object.keys(item)[0]}}</div>
          </div>
          <!-- 时间 -->
          <div style="color: #999999;">{{item[Object.keys(item)[0]]}}</div>
        </div>
      </div>
    </van-action-sheet>

    <!-- 地图 -->
    <div id="canvas" v-if="canvasShow" style="opacity: 1;z-index: 1;">
      <div v-if="transportStatus" class="map" id="container"></div>
      <div style="display: flex;justify-content: space-between;width: 100%;position: fixed;top: 20px;">
        <div @click="navLift" class="updateMap"><img src="../../../assets/back_black.png" alt=""></div>
        <div @click="navRight" class="updateMap"><img src="../../../assets/img/updateMap.png" alt=""></div>
      </div>
    </div>

  </div>
</template>

<script>
  import store from "@/store";
  import { ordertracking, findorders, cancelorders, payment } from '/src/api/takeOut/cancelReason/index.js'
  import { Toast } from "vant";
  import AMapAPILoader from '@amap/amap-jsapi-loader';
  export default {
    name: "takeOutOrderDetail",
    components: {},
    data() {
      return {
        amap: {// 地图数据
          zoom: 16,
          center: [117.229330, 31.772060],// 初始化经纬度
          markers: [
            {// 用户
              position: [],
              icon: require("../../../assets/yonghu.png"),
              offset: [-30, -57]
            },
            {// 商户
              position: [],
              icon: require("../../../assets/shanghu.png"),
              offset: [-30, -57]
            },
            {// 骑手
              position: [117.227330, 31.772060],
              icon: require("../../../assets/qishou.png"),
              offset: [-40, -77],
              label: { content: '骑手正在赶往商家 距商家110m|1分钟', offset: [-120, -40] }
            },
          ]
        },
        move: {
          state: "",// 开始坐标
          end: "",// 结束坐标
          num: '',// 每次滑动的距离
          currentType: false,
          position: true,
          distance: document.body.clientHeight / 2,
          currentHeight: 0,
        },
        orderId: '',//订单id
        currentData: {
          userRemark: '',//下单备注
          acceptAddress: '',//收获地址
          acceptPhone: '',//下单人号码
          pricePackage: '',//打包费
          priceTransportUser: '',//配送费
          priceCouponReduce: '',//优惠券减少费用
          payFee: '',//支付费用（合计）
          payFeeReduce: '',//优惠费用
          acceptName: '',//下单人姓名
          orderNo: '',//订单id 
          createTime: '',//下单时间
          payType: '',//支付方式3支付宝 4微信
          couponReduceId: '',//优惠券id
          complaintStatus: '',//投诉状态 0未发生 1受理中 2已结束
          takeoutOrderGoods: {// 订单商品
            id: '',// 订单商品id
            createTime: '',// 创建时间
            orderId: '',// 所属订单id
            goodsId: '',// 订单商品id
            goodsName: '',// 商品名
            goodsNum: '',// 订单商品数量
            goodsPrice: '',// 商品购买时单价
            packingPrice: '',// 打包费
            goodsTotalPrice: '',// 商品价格（加上规格之后的*数量的价格）
            goodsStandardIds: '',// 商品规格 规格id:选项id_规格id:选项id
            goodsStandardNames: '',// 商品规格名字 用于显示 口味:微辣_分量:1人份
            goodsUrl: '',// 商品图片
          }
        },//订单数据
        tracking: {
          currentTime: '08:00',// 当前时间
          cancelTime: '08:01',// 取消时间
          sendAtshopTime: '08:04',//骑手到店时间
          shopCompleteTime: '08:02',//商家出餐时间
          shopAcceptTime: '08:01',//商家接单时间
          userPayTime: '08:03',//用户支付时间
          createTime: '',//创建（下单）时间
        },//订单跟踪状态
        actionShow: false,// 订单跟踪弹出层
        navigationShow: false,// 导航栏
        currentType: 0,
        overtime: false,// 是否超时
        time: 900000,// 定时
        titleCurrent: '',// 头部内容
        buttonTopContent: '',// 按钮框头部标题
        refund: false, // 退款框
        merchantTips: '商家正在备餐',
        riderTips: '骑手正在赶往商家',
        expectDeliveTime: null,//预计送达时间
        DeliveTime: '',
        m: '',// 距离
        mishu: '商家',
        transportStatus: false,//地图是否加载
        requestStatus: false,// 是否监听骑手位置
        canvasShow: false,// 是否显示地图
        AMap: '',
        map: '',
        marker1: '',// 用户
        marker2: '',// 商家
        marker3: '',// 骑手
        csshijianc: '',
        theRiderPosition: false,
        token: '',
      };
    },
    created() {
      window.closePage = this.closePage
      // 获取token
      if (!store.state.token) {
        store.commit("setSession", '$2a$10$7oaOFgOz8xEf2FnrakxNQ.nbB23aP8qnAuAUp8FMj0yNyRcuBfXsq')
        console.log('本地没有token')
      }
      this.token = store.state.token
      // 获取订单ID
      if (this.$route.query.id) {
        this.orderId = this.$route.query.id
        console.log('接收到订单ID')
      } else {
        this.orderId = '1503639826211192833'
        // this.orderId = '1503205307607887873'
        console.log('没有接收到订单ID')
      }
      this.details()
    },
    mounted() {
      document.documentElement.scrollTop = 0
      document.documentElement.style.overflow = 'hidden'
      console.log(store.state.token)
    },
    computed: {
      // 以优惠价格
      priceCouponReduce() {
        let num = 0
        num += Number(this.currentData.priceCouponReduce) + Number(this.currentData.priceFullReduce)
        if (num) {
          return num
        } else {
          return 0
        }
      }
    },
    watch: {
      // 定时查询骑手位置
      requestStatus(newVal) {
        if (!newVal) return
        var timer = setInterval(() => {
          console.log('获取骑手位置')
          this.rider()
        }, 30000)
        if (!this.theRiderPosition && !this.sendAcceptTime) {
          clearInterval(timer)
        }
        this.$once('hook:boforeDestory', () => {
          clearInterval(timer)
        })
      }
    },
    methods: {
      // 更新骑手markers
      markers(AMap, map, rider) {
        if (rider) {
          map.clearMap();
          // 骑手状态
          switch (String(this.currentData.transportStatus)) {
            case '0':
              this.riderTips = ''
              break;
            case '1':
              this.riderTips = ''
              break;
            case '2':
              this.requestStatus = true
              // this.currentData.transportSendId = 1
              this.pathPlanning('merchant')
              this.riderTips = '骑手正在赶往商家'
              this.mishu = '商家'
              break;
            case '3':
              this.requestStatus = true
              // this.currentData.transportSendId = 1
              this.pathPlanning('merchant')
              this.riderTips = '骑手已到店待取餐'
              break;
            case '4':
              this.requestStatus = true
              // this.currentData.transportSendId = 1
              this.pathPlanning('user')
              this.riderTips = '骑手配送中'
              this.mishu = '您'
              break;
            case '5':
              this.requestStatus = false
              this.riderTips = '已送达'
              break;
            case '9':
              break;
          }
          // 更新距离和时间
          this.marker3 = new AMap.Marker({
            position: new AMap.LngLat(this.amap.markers[2].position[0], this.amap.markers[0].position[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            icon: this.amap.markers[2].icon,
            offset: this.amap.markers[2].offset,
          });
          // 剩余时间
          let time1 = this.DeliveTime.replace(/-/g, "/")
          let date = new Date(time1);
          let time2 = date.getTime();
          let time3 = (new Date().getTime())
          let scheduledTime = Math.ceil(((time2 - time3) / 1000) / 60)
          if (scheduledTime == 0) {
            scheduledTime = 1
          }
          let scheduledTime2 = scheduledTime > 0 ? scheduledTime : '超时' + Math.abs(scheduledTime)
          this.expectDeliveTime = scheduledTime2

          // 渲染图标
          this.marker3.setLabel({
            offset: new AMap.Pixel(0, 0),  //设置文本标注偏移量
            content: "<div style=\"text-align: center;border-radius: 7px;border: #fff;width: 120%;height: 120%;background: #fff;margin: -10% -10%;padding: 5%;\">\n" +
              "            <p style=\"font-size: 14px;color: #666666;margin-bottom: 5px;\">" + this.riderTips + "</p>\n" +
              "            <p style=\"font-size: 12px;color: #999999;\">距离" + this.mishu + " <span style=\"color: #ff0000;\">" + (this.m / 1000).toFixed(1) + " KM</span>|<span style=\"color: #ff6d00;\">" + this.expectDeliveTime + "分钟</span></p>\n" +
              "        </div>", //设置文本标注内容
            direction: 'top' //设置文本标注方位
          })
          let markers = [this.marker1, this.marker2, this.marker3]
          map.add(markers);
        } else {
          let markers = [this.marker1, this.marker2]
          map.add(markers);
        }
      },
      // 查询骑手位置
      rider() {
        this.$axios.post(`/takeoutlocation/get?id=${this.currentData.userId}&type=2`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "client-type": "app",
            "third-session": store.state.token,
            "token": store.state.token,
          }
        }).then(res => {
          console.log(res)
          if (res.data.code != 0) {
            if (res.data.data.lat || res.data.data.lot) {
              let arr = []
              arr.push(Number(res.data.data.lot).toFixed(6))
              arr.push(Number(res.data.data.lat).toFixed(6))
              // 替换骑手经纬度
              this.amap.markers[2].position = arr

              // 测试
              // this.amap.markers[2].position[0] = Number(Number(this.amap.markers[2].position[0]) + 0.0001).toFixed(6)

              this.markers(this.AMap, this.map, true)
              console.log('更新markers', this.amap.markers[2].position[0])
            }
          } else {
            this.requestStatus = false
            if (res.data.msg) {
              Toast(res.data.msg)
            }
          }
        })
      },
      //加载地图
      initMap() {
        AMapAPILoader.load({
          key: "0bd6e30b24d6b7efc7c11b4423b5ad3b",             // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.Marker', 'AMap.Map', 'AMap.GeometryUtil', 'AMap.Riding', 'AMap.Geocoder'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          Loca: {                // 是否加载 Loca， 缺省不加载
            "version": '2.0.0'  // Loca 版本，缺省 1.3.2
          },
        }).then((AMap) => {
          this.AMap = AMap
          let map = new AMap.Map("container", {  //设置地图容器id
            // viewMode:"3D",    //是否为3D地图模式
            zoom: this.amap.zoom,           //初始化地图级别
            center: this.amap.center, //初始化地图中心点位置
            resizeEnable: true,
          });

          map.panTo(this.amap.markers[0].position[0]);
          // let p1 = [this.amap.markers[0].position[0], this.amap.markers[0].position[1]]
          // let p2 = [this.amap.markers[1].position[0], this.amap.markers[1].position[1]]
          // let m = AMap.GeometryUtil.distance(p1, p2);//距离
          // this.amap.markers[2].label.content = "骑手正在赶往商家 距商家" + m.toFixed(0) + "米|" + (m / 95).toFixed(0) + "分钟"
          //设置地图缩放比例
          if ((this.m / 1000) <= 1) {
            map.setZoom(16.5)
            console.log('缩放16')
          } else if ((this.m / 1000) > 1 && (this.m / 1000) <= 2) {
            console.log('缩放16')
            map.setZoom(17)
          } else if ((this.m / 1000) > 2 && (this.m / 1000) <= 3) {
            console.log('缩放14')
            map.setZoom(14)
          } else if ((this.m / 1000) > 3 && (this.m / 1000) <= 5) {
            console.log('缩放13')
            map.setZoom(13)
          } else if ((this.m / 1000) > 5 && (this.m / 1000) <= 10) {
            console.log('缩放12')
            map.setZoom(12)
          } else {
            console.log('缩放12')
            map.setZoom(12)
          }
          // 用户markers
          this.marker1 = new AMap.Marker({
            position: new AMap.LngLat(this.amap.markers[0].position[0], this.amap.markers[0].position[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            icon: this.amap.markers[0].icon,
            offset: this.amap.markers[0].offset,
            label: this.amap.markers[0].label
          });
          // 商家markers
          this.marker2 = new AMap.Marker({
            position: new AMap.LngLat(this.amap.markers[1].position[0], this.amap.markers[0].position[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            icon: this.amap.markers[1].icon,
            offset: this.amap.markers[1].offset,
          });
          this.marker2.setLabel({
            offset: new AMap.Pixel(0, 0),  //设置文本标注偏移量
            content: "<div style=\"text-align: center; border-radius: 7px;border: #fff;width: 120%;height: 120%;background: #fff;margin: -10% -10%;padding: 5%;\">\n" +
              "            <p style=\"font-size: 14px;color: #666666;\">" + this.merchantTips + "</p>" +
              "        </div>", //设置文本标注内容
            direction: 'top' //设置文本标注方位
          })

          // 骑手markers
          if (this.amap.markers[2] !== undefined && this.currentData.transportSendId) {
            // let marker3 = new AMap.Marker({
            //   position: new AMap.LngLat(this.amap.markers[2].position[0], this.amap.markers[0].position[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            //   icon: this.amap.markers[2].icon,
            //   offset: this.amap.markers[2].offset,
            // });

            this.markers(AMap, map, true)
          } else {
            this.markers(AMap, map, false)
          }
          // 地图中心点
          map.panBy(0, -70)
          this.map = map
          console.log('地图渲染完成')
        }).catch(e => {
          console.log(e);
        })

      },
      // 路线规划
      pathPlanning(type) {
        type = 'user'
        var params = {}
        // 骑手到商家时间
        if (type == 'merchant') {
          params = {
            key: '7a23b53bf4e82a7d8c0205304656b8f4',
            origin: this.amap.markers[2].position.join(','),
            destination: this.amap.markers[1].position.join(','),
          }
        } else if (type == 'user') {// 骑手到用户距离
          params = {
            key: '7a23b53bf4e82a7d8c0205304656b8f4',
            origin: this.amap.markers[2].position.join(','),
            destination: this.amap.markers[0].position.join(','),
          }
        }
        this.$axios({
          method: 'GET',
          url: '/v4/direction/bicycling',
          params
        }).then(res => {
          console.log(res)
          // 距离m
          this.m = Number(res.data.data.paths[0].distance)
          // 时间s
          this.expectDeliveTime = Number(res.data.data.paths[0].duration)
          console.log(this.m + 'm', this.expectDeliveTime + 's')
          // console.log(this.amap.markers[2].position.join(','))
        });
      },
      // 手指触摸
      touchstart(e) {
        if (!this.canvasShow) return
        this.move.state = e.changedTouches[0].screenY
        let Dom = document.getElementById('element')
        this.move.currentType = Dom.offsetTop == 0
        if (this.move.currentType) {
          this.move.position = false
          document.getElementById('element').style.position = "relative"
          console.log('置顶')
        } else {
          this.move.position = true
          document.getElementById('element').style.position = "relative"
          console.log('底部')
        }
      },
      // 滑动
      touchmove(e) {
        if (!this.canvasShow) return
        this.move.num = e.changedTouches[0].screenY - this.move.state
        let Dom = document.getElementById('element')
        let currentMove = Dom.style.transform.slice(11, Dom.style.transform.search('px'))
        // console.log(currentMove)
        if (Math.abs(currentMove) < 0) {
          this.$refs['container'].style.transition = 'all 0s'
          this.$refs['container'].style.transform = `translateY(${this.move.num + this.move.distance}px)`
        }

      },
      // 手指离开屏幕
      touchend(e) {
        this.move.distance += this.move.num
        if (!this.canvasShow) return
        // 滑动小于指定距离不触发
        if (Math.abs(this.move.num) > 50) {
          this.move.end = e.changedTouches[0].screenY
          let num = (document.body.clientHeight / 2)
          // 向上滑
          if (this.move.num <= 0 && !this.move.position) {
            console.log('向上滑')
            document.getElementById('element').style.position = "fixed"
            //滑动框
            this.$refs['container'].style.transition = 'all .5s ease'
            this.$refs['container'].style.transform = "translateY(" + 0 + "px)"
            // 地图
            document.getElementById('canvas', 'formStatus').style.transition = 'all .5s ease'
            document.getElementById('canvas').style.opacity = '0'
            // 导航栏
            this.navigationShow = true
            this.move.distance = 0
          } else if (this.move.num > 0) {// 向下滑
            console.log('向下滑')
            document.getElementById('element').style.position = "fixed"
            // 地图
            document.getElementById('canvas', 'formStatus').style.transition = 'all .5s ease'
            document.getElementById('canvas').style.opacity = '1'
            //滑动框
            this.$refs['container'].style.transition = 'all .5s ease'
            this.$refs['container'].style.transform = `translateY(${(num)}px)`
            // 导航栏
            this.navigationShow = false
            this.move.distance = num
            this.move.position = false
          }
        }
      },
      // 已支付取消订单是否超时
      payDate(time, type) {
        var time2 = time.replace(/-/g, "/")
        var date = new Date(time2);
        // 有三种方式获取
        var time1 = date.getTime();
        // var time2 = date.valueOf();
        // var time3 = Date.parse(date);
        // console.log(time1);//1398250549123
        // console.log(time2);//1398250549123
        // console.log(time3);//1398250549000
        if (type == 1) {
          if ((Number(time1) + 120000) < Date.parse(new Date())) {
            console.log('已付款超时')
            return false
          } else {
            console.log('已付款未超时')
            return true
          }
        } else if (type == 2) {
          if ((Number(time1) + Number(86400000)) < Date.parse(new Date())) {
            console.log('已送达超时')
            console.log(time, Date.parse(new Date()))
            return false
          } else {
            console.log('已送达未超时')
            return true
          }
        } else if (type == 3) {
          // 到期时间
          let expireTime = Number(time1) + Number(900000)
          // 当前时间
          let timestamp = Number(new Date().getTime());
          // 剩余时间
          let currentTime = Number(expireTime) - Number(timestamp)
          return currentTime
        }
      },
      // 跳转
      push(type) {
        if (type == 1) {
          // 申请售后
          let isShow = this.currentData.orderStatus == 4
          // if (this.currentData.complaintStatus == 1) return
          this.$router.push({ name: 'afterSale', params: { type, obj: this.currentData, isShow } })
        } else
          if (type == 2) {
            // 申请退款
            if (this.currentData.cancelStatus == 3) return
            this.$router.push({ name: 'afterSale', params: { type, obj: this.currentData } })
          }
      },
      // 订单超时
      finish() {
        if (this.currentData.orderStatus == 0) {
          this.navigationShow = true
          this.canvasShow = false
          this.refund = false
          this.buttonTopContent = '订单已作废'
          this.currentType = 4
          this.titleCurrent = '已作废'
          console.log('超时')
        }
      },
      // 订单跟踪请求
      track() {
        let param = {
          orderId: this.currentData.orderNo,//订单号
        }
        ordertracking(param).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.tracking = res.data.data
            let arr = []
            for (let key in this.tracking) {
              if (this.tracking[key] != 0 && this.tracking[key] != null) {
                // var time2 = time.replace(/-/g, "/")
                // var date = new Date(time2);
                // // 有三种方式获取
                // var time1 = date.getTime();
                arr.push(this.tracking[key])
              }
            }
            // console.log(arr)
            var compare = function (x, y) {//比较函数
              if (x < y) {
                return -1;
              } else if (x > y) {
                return 1;
              } else {
                return 0;
              }
            }
            arr = arr.sort(compare)
            let array = []
            let key = []
            for (let i = 0; i < arr.length; i++) {
              for (let k in this.tracking) {
                if (this.tracking[k] == arr[i] && this.tracking[k] != 0) {
                  if (key.indexOf(k) < 0) {
                    let obj = {}
                    switch (k) {
                      case 'createTime':
                        key.push(k)
                        array.push(obj)
                        obj['下单时间'] = this.tracking[k]
                        break;
                      case 'cancelTime':
                        key.push(k)
                        array.push(obj)
                        obj['取消时间'] = this.tracking[k]
                        break;
                      case 'userPayTime':
                        key.push(k)
                        array.push(obj)
                        obj['支付时间'] = this.tracking[k]
                        break;
                      case 'shopAcceptTime':
                        key.push(k)
                        array.push(obj)
                        obj['商家接单时间'] = this.tracking[k]
                        break;
                      case 'shopCompleteTime':
                        key.push(k)
                        array.push(obj)
                        obj['商家出餐时间'] = this.tracking[k]
                        break;
                      case 'sendAcceptTime':
                        key.push(k)
                        array.push(obj)
                        obj['骑手接单时间'] = this.tracking[k]
                        break;
                      case 'sendAtshopTime':
                        key.push(k)
                        array.push(obj)
                        obj['骑手到店时间'] = this.tracking[k]
                        break;
                      case 'sendGotTime':
                        key.push(k)
                        array.push(obj)
                        obj['骑手取货时间'] = this.tracking[k]
                        break;
                      case 'expectDeliveTime':
                        // key.push(k)
                        // array.push(obj)
                        // obj['预计送达时间'] = this.tracking[k]
                        break;
                      case 'cancelStatus':
                        break;
                      case 'cancelApply':
                        break;
                      case 'fetchStatus':
                        break;
                      case 'complaintStatus':
                        break;
                      case 'providerReplyResult':
                        break;
                      case 'officeApplyStatus':
                        break;
                      case 'officeMakingResult':
                        // key.push(k)
                        // array.push(obj)
                        // obj['实际送达时间'] = this.tracking[k]
                        break;
                      case 'userComplaintTime':
                        key.push(k)
                        array.push(obj)
                        obj['投诉时间'] = this.tracking[k]
                        break;
                      case 'shopReplyTime':
                        key.push(k)
                        array.push(obj)
                        obj['商家处理投诉时间'] = this.tracking[k]
                        break;
                      case 'officeProcessTime':
                        key.push(k)
                        array.push(obj)
                        obj['平台处理投诉时间'] = this.tracking[k]
                        break;

                    }
                  }
                }
              }
            }
            // console.log(array)
            this.tracking = array
          }
        })
      },
      // 订单跟踪弹出层
      popup() {
        if (this.navigationShow && this.currentData.orderStatus != 0 && this.currentData.orderStatus != 10) {
          this.actionShow = true
        }
      },
      // 取消订单
      submit(type) {
        // 已付款取消订单
        if (type == 5) {
          this.$router.push({ name: 'cancelReason', params: { type: 2, obj: this.currentData, id: this.orderId } })
        } else if (type == 1) {// 待支付取消订单
          let param = {
            id: this.currentData.orderNo,//订单id
          }
          cancelorders(param).then(res => {
            console.log(res)
            console.log('取消订单')
            if (res.data.code == 0) {
              Toast(res.data.data);
              this.$router.back()
            }
          })
        }

      },
      // 订单详情
      details() {
        let param = {
          orderId: this.orderId,// 订单id
        }
        findorders(param).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.currentData = res.data.data
            // this.currentData.orderStatus = 4
            // this.currentData.transportStatus = 4
            // this.canvasShow = false
            // 替换商家经纬度
            if (this.currentData.lat || this.currentData.lot) {
              let arr = []
              arr.push(Number(res.data.data.lot))
              arr.push(Number(res.data.data.lat))
              // console.log(this.amap.markers[1].position, arr)
              this.amap.markers[1].position = arr
              // console.log(arr)
            }
            // 替换用户经纬度
            if (this.currentData.acceptLot || this.currentData.acceptLat) {
              let arr = []
              arr.push(this.currentData.acceptLot)
              arr.push(this.currentData.acceptLat)
              this.amap.markers[0].position = arr
            }
            // 替换初始化地图中心点经纬度(初始化以用户为中心)
            if (this.currentData.acceptLot || this.currentData.acceptLat) {
              let arr = []
              arr.push(this.currentData.acceptLot)
              arr.push(this.currentData.acceptLat)
              this.amap.center = arr
              // console.log(arr)
            }
            // 订单主状态
            switch (String(this.currentData.orderStatus)) {
              case '0':
                this.navigationShow = true
                this.canvasShow = false
                this.currentType = 1
                this.titleCurrent = '待支付'
                this.theRiderPosition = false
                console.log('待支付')
                break;
              case '1':
                this.navigationShow = false
                this.canvasShow = true
                this.currentType = 5
                this.merchantTips = '待商家接单'
                this.titleCurrent = '待商家接单'
                this.theRiderPosition = false
                console.log('待商家接单')
                break;
              case '2':
                this.currentType = 5
                this.navigationShow = false
                this.canvasShow = true
                this.merchantTips = '商家出餐中'
                this.titleCurrent = '出餐中'
                this.theRiderPosition = true
                console.log('出餐中')
                break;
              case '3':
                this.currentType = 5
                this.navigationShow = false
                this.canvasShow = true
                this.merchantTips = '商家已出餐'
                this.titleCurrent = '商家已出餐'
                this.theRiderPosition = true
                console.log('已出餐')
                break;
              case '4':
                this.currentType = 5
                this.navigationShow = false
                this.canvasShow = true
                this.merchantTips = '商家已出餐'
                this.titleCurrent = '骑手配送中'
                this.theRiderPosition = true
                console.log('骑手配送中')
                break;
              // case '5':
              //   this.currentType = 2
              //   this.navigationShow = true
              //   this.canvasShow = false
              //   this.titleCurrent = '已送达'
              //   this.theRiderPosition = false
              //   console.log('已送达')
              //   break;
              case '8':
                this.currentType = 4
                this.navigationShow = false
                this.canvasShow = true
                this.refund = true
                this.merchantTips = '待处理'
                this.buttonTopContent = '用户取消待商家处理'
                this.theRiderPosition = false
                this.titleCurrent = '取消订单'
                console.log('用户取消待商家处理')
                break;
              case '9':
                this.navigationShow = true
                this.canvasShow = false
                if (res.data.data.userPayTime) {
                  this.refund = true
                } else {
                  this.refund = false
                }
                this.theRiderPosition = false
                this.buttonTopContent = '订单已取消'
                this.currentType = 4
                this.titleCurrent = '已取消'
                console.log('已取消')
                break;
              case '10':
                this.navigationShow = true
                this.canvasShow = false
                this.refund = false
                this.theRiderPosition = false
                this.buttonTopContent = '订单已作废'
                this.currentType = 4
                this.titleCurrent = '已作废'
                console.log('已作废')
                break;
              case '11':
                this.navigationShow = true
                this.canvasShow = false
                this.currentType = 4
                this.theRiderPosition = false
                this.buttonTopContent = '商家拒绝接单'
                this.merchantTips = '商家拒绝接单'
                this.titleCurrent = '商家拒绝接单'
                console.log('商家拒绝接单')
                break;
              case '12':
                this.navigationShow = true
                this.canvasShow = false
                if (res.data.data.userPayTime) {
                  this.refund = true
                } else {
                  this.refund = false
                }
                this.theRiderPosition = false
                this.buttonTopContent = '无骑手接单'
                this.currentType = 4
                this.titleCurrent = '无骑手接单'
                console.log('无骑手接单')
                break;
              case '20':
                this.navigationShow = true
                this.canvasShow = false
                this.refund = false
                this.theRiderPosition = false
                this.currentType = 3
                this.titleCurrent = '已完成'
                console.log('已完成')
                break;
              case '21':
                this.navigationShow = true
                this.canvasShow = false
                this.refund = false
                this.currentType = 3
                this.theRiderPosition = false
                this.titleCurrent = '已评价'
                console.log('已评价')
                break;
            }
            // 订单副状态
            switch (String(this.currentData.cancelStatus)) {
              case '2':
                this.navigationShow = true
                this.canvasShow = false
                if (res.data.data.userPayTime) {
                  this.refund = true
                } else {
                  this.refund = false
                }
                this.theRiderPosition = false
                this.buttonTopContent = '订单已取消'
                this.currentType = 4
                this.titleCurrent = '已取消'
                console.log('已取消')
                break;
              case '3':
                this.buttonTopContent = '用户取消待商家处理'
                this.titleCurrent = '用户取消待商家处理'
                console.log('用户取消待商家处理')
                break;
            }
            // 骑手状态
            switch (String(this.currentData.transportStatus)) {
              case '0':
                this.riderTips = ''
                break;
              case '1':
                this.riderTips = ''
                break;
              case '2':
                this.requestStatus = true
                // this.pathPlanning('merchant')
                this.pathPlanning('merchant')
                console.log('骑手正在赶往商家')
                this.riderTips = '骑手正在赶往商家'
                break;
              case '3':
                this.requestStatus = true
                this.riderTips = '骑手已到店待取餐'
                console.log('骑手已到店待取餐')
                break;
              case '4':
                this.requestStatus = true
                this.pathPlanning('user')
                console.log('骑手配送中')
                this.riderTips = '骑手配送中'
                break;
              case '5':
                this.requestStatus = false
                this.riderTips = '已送达'
                break;
              case '9':
                break;
            }
            // 地图
            switch (String(this.currentData.orderStatus)) {
              case '1':
              case '2':
              case '3':
                this.mishu = '商家'
                // eslint-disable-next-line no-case-declarations
                // let date = new Date((this.currentData.expectDeliveTime).replace(/-/g, '/'));
                // eslint-disable-next-line no-case-declarations
                // let time1 = date.getTime(date)
                // eslint-disable-next-line no-case-declarations
                // let time2 = new Date().getTime()
                // eslint-disable-next-line no-case-declarations
                // let time3 = time1 - time2
                // console.log(time2)
                // eslint-disable-next-line no-case-declarations
                // this.expectDeliveTime = (time3 / 1000 / 60).toFixed(0)
                this.transportStatus = true
                this.initMap()
                break;
              case '4':
                this.mishu = '您'
                this.transportStatus = true
                this.initMap()
                break;
              case '8':
                this.transportStatus = true
                this.initMap()
                break;
              case '9':
                if (this.currentData.orderStatus !== 0) {
                  this.transportStatus = true
                  this.initMap()
                }
                break;
            }
            // 售后状态
            switch (String(this.currentData.complaintStatus)) {
              case '1':
                this.buttonTopContent = '售后受理中'
                this.titleCurrent = '售后受理中'
                console.log('售后受理中')
                break;
            }
            // 跟踪请求
            this.track()
            // 滑动框
            if (this.canvasShow) {
              document.getElementById('element').style.transform = `translateY(${((document.body.clientHeight / 2))}px)`
            }

            for (let i = 0; i < this.currentData.takeoutOrderGoods.length; i++) {
              let str = this.currentData.takeoutOrderGoods[i].goodsStandardNames.split('_')
              // console.log(str)
              str = str.map(item => {
                return item.split(':')[1]
              })
              this.currentData.takeoutOrderGoods[i].goodsStandardNames = str
              this.currentData.takeoutOrderGoods[i].goodsUrl = this.currentData.takeoutOrderGoods[i].goodsUrl[0]
            }
            // 判断是否超出24h
            if (this.currentData.userPayTime) {
              if (this.payDate(this.currentData.userPayTime, 2)) {
                this.overtime = true
                if (this.currentData.cancelStatus == 1 && this.currentData.complaintStatus != 1) {
                  this.titleCurrent = '商品已送达'
                }
                console.log('未超时')
              } else {
                this.overtime = false
                this.titleCurrent = '订单已完成'
                console.log('超时')
              }
            }
            // 判断是否超出15分钟
            if (this.currentData.orderStatus == 0) {
              if (this.payDate(this.currentData.createTime, 3) >= 0) {
                this.time = this.payDate(this.currentData.createTime, 3)
              } else {
                this.navigationShow = true
                this.canvasShow = false
                this.refund = false
                this.buttonTopContent = '订单已作废'
                this.currentType = 4
                this.titleCurrent = '已作废'
                console.log('已作废')
              }
            }
            this.DeliveTime = this.currentData.expectDeliveTime
            let expectDeliveTime = this.currentData.expectDeliveTime.slice(11, 16)
            this.currentData.expectDeliveTime = expectDeliveTime
          } else {
            this.requestStatus = false
          }
        })
      },
      // 联系商家
      contact1() {
        let id = this.currentData.shopIm// 商家im账号
        this.$contact(id)
      },
      // 再来一单
      recur() {
        this.$router.push({ path: '/shopList', query: { id: this.orderId, session: store.state.token } })
      },
      // 地图导航栏
      navLift() {
        this.$router.back()
      },
      navRight() {
        this.details()
      },
      // 立即支付
      onSubmit() {
        payment(this.currentData.orderNo, this.currentData.payType)
      },
      // 支付后反馈
      closePage() {
        console.log('支付反馈')
        this.details()
      }
    },
  }
</script>

<style lang="less" scoped>
  /deep/.van-nav-bar__content {
    background-color: #f6f6f6;
  }

  /deep/.van-nav-bar__placeholder {
    position: relative;
    z-index: 200;
  }

  /deep/.van-dialog__message {
    font-size: 17px;
    color: #353535;
  }

  .map {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  .btn a {
    font-family: none
  }

  .btn a:hover {
    font-family: none
  }

  .updateMap {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ffffff;
    position: relative;
    background-color: #fff;
    margin: 10px 20px;
  }

  .status {
    padding: 8px 16px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 29px;

    .van-count-down {
      display: inline;
      font-size: 21px;
    }
  }

  .defaultStyle {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #D8D8D8;
  }

  .currentStyle {
    width: 2px;
    height: 2px;
    border-radius: 100%;
    border: 3px solid #FF6538;
  }

  .box {
    margin: 0 10px 10px;
    padding: 11px;
    background-color: #fff;
    border-radius: 10px;

    .box-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 11px;
    }

    .box-content {
      padding-top: 8px;

      .content-inner {
        display: flex;
        justify-content: space-between;
        color: #999;
        line-height: 2;
      }
    }
  }

  .pay-explain {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
  }

  .btns-box {
    display: flex;
    padding: 12px 0 16px;

    .btn {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid #e5e5e5;

      &:last-of-type {
        border-right: none;
      }

      .btn-img {
        width: 24px;
        height: 24px;
      }

      .btn-text {
        font-size: 12px;
        margin-top: 5px;
        color: #333;
      }
    }
  }

  .goods {
    .goods-item {
      display: flex;
      padding: 10px 0 16px;
      border-bottom: 1px solid #e5e5e5;
      color: #999;

      .item-title {
        display: flex;
        justify-content: space-between;
        color: #333;
      }
    }
  }
</style>