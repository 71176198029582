import https from "../../../router/https";
import { openPay } from "../../../common/index";
import qs from "qs";
import { Toast } from "vant";

//取消订单
function cancelorders(param) {
  return https.fetchPost(
    "/takeout/user/takeoutordergoods/cancelorders",
    null,
    param
  );
}
// 订单跟踪
function ordertracking(param) {
  return https.fetchPost(
    "/takeout/user/takeoutordergoods/ordertracking",
    null,
    param
  );
}

// 订单详情
function findorders(param) {
  return https.fetchPost(
    "/takeout/user/takeoutordergoods/findorders",
    null,
    param
  );
}

// 申请退款
function twominutescancelorder(param) {
  return https.fetchPost("/takeout/user/twominutescancelorder", null, param);
}

// 申请售后
function complaint(param) {
  return https.fetchPost(
    "/takeout/user/takeout/complaint/complaint",
    null,
    param
  );
}

// 支付
const paynow = (data) => {
  return https.fetchPost("/takeout/user/paynow", data);
};

// 立即支付
function payment(orderId, type) {
  if (type == 4) {
    // 微信
    paynow(
      qs.stringify({
        orderId,
        // paymentMode: type,
      })
    ).then((res) => {
      if (res.data.code === 0) {
        console.log(res.data);
        openPay(res, "1");
      } else {
        if (res.data.msg) {
          Toast(res.msg);
        }
      }
    });
  } else if (type == 3) {
    // 支付宝
    paynow(
      qs.stringify({
        orderId,
        // paymentMode: type,
      })
    ).then((res) => {
      if (res.data.code === 0) {
        console.log(res.data);
        openPay(res.data, "2");
      } else {
        if (res.data.msg) {
          Toast(res.data.msg);
        }
      }
    });
  }
}

export {
  cancelorders,
  ordertracking,
  twominutescancelorder,
  complaint,
  findorders,
  payment,
};
